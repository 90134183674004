<!--
 * @Description: 咨询回复服务单
 * @Author: ChenXueLin
 * @Date: 2021-11-23 16:33:11
 * @LastEditTime: 2022-06-22 10:34:43
 * @LastEditors: ChenXueLin
-->
<template>
  <div
    class="content-view-wrapper pd-10"
    v-loading="loading"
    :element-loading-background="loadingBackground"
  >
    <div class="content-view">
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 start -->
          <template>
            <div class="primaryTitle">咨询回复服务单</div>
            <div class="baseInfo-box">
              <div class="edit-title">基本信息</div>
              <el-form
                ref="baseInfoForm"
                class="editForm"
                label-position="right"
                :inline="true"
                label-width="120px"
                :rules="rules"
                :model="baseInfoForm"
              >
                <el-form-item label="客户名称" prop="corpId">
                  <e6-vr-select
                    v-model="baseInfoForm.corpId"
                    :data="corpList"
                    placeholder="客户名称"
                    title="客户名称"
                    clearable
                    virtual
                    remote
                    :is-title="true"
                    @filterChange="handleLoadCorpFilter"
                    @change="handleChange"
                    :props="{
                      id: 'corpId',
                      label: 'corpName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="指派类型" prop="solueIsDept">
                  <el-radio-group v-model="baseInfoForm.solueIsDept">
                    <el-radio :label="1">部门</el-radio>
                    <el-radio :label="0">员工</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="指派部门"
                  prop="departmentId"
                  v-if="baseInfoForm.solueIsDept == 1"
                >
                  <!-- 部门下拉框 -->
                  <e6-select
                    v-model="baseInfoForm.departmentId"
                    :dataList="treeData"
                    :filterable="true"
                    :slotTemplate="'tree'"
                    :multiple="false"
                    :issingleleaf="false"
                    ref="t_select"
                  ></e6-select>
                </el-form-item>
                <el-form-item label="指派处理人" prop="solveRpid" v-else>
                  <all-user-search
                    v-model="baseInfoForm.solveRpid"
                    clear
                    placeholder="指派处理人"
                    title="指派处理人"
                    :propsConfig="{
                      id: 'employeeId',
                      label: 'userName'
                    }"
                  >
                  </all-user-search>
                </el-form-item>
                <el-form-item label="联系人" prop="linkmanrpid">
                  <div class="select-content">
                    <e6-vr-select
                      v-model="baseInfoForm.linkmanrpid"
                      :data="contactList"
                      placeholder="联系人"
                      title=""
                      :props="{
                        id: 'contactId',
                        label: 'contactName'
                      }"
                      clearable
                      @change="handleContact"
                      @clear="handleClear"
                    ></e6-vr-select>
                    <el-button
                      type="text"
                      style="margin-left: 10px"
                      @click="addConcat"
                    >
                      添加联系人
                    </el-button>
                  </div>
                </el-form-item>
                <el-form-item label="联系电话" prop="contactPhone">
                  <el-input
                    placeholder="联系电话"
                    v-model="baseInfoForm.contactPhone"
                  ></el-input>
                </el-form-item>

                <el-form-item label="要求完成时间" prop="expecttime">
                  <el-date-picker
                    v-model="baseInfoForm.expecttime"
                    type="datetime"
                    placeholder="选择日期"
                    value-format="timestamp"
                    popper-class="special"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="联系地址" prop="defaultCity">
                  <e6-vr-select
                    style="width:300px"
                    v-model="baseInfoForm.defaultCity"
                    :data="addressList"
                    placeholder="联系地址"
                    title="联系地址"
                    virtual
                    clearable
                    :is-title="true"
                    remote
                    @filterChange="handleLoadAddressFilter"
                    @change="handleCitySelect"
                    :props="{
                      id: 'fullName',
                      label: 'fullName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="详细地址" prop="insAddress">
                  <el-input
                    placeholder="详细地址"
                    v-model="baseInfoForm.insAddress"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                  <el-input
                    v-model="baseInfoForm.remark"
                    placeholder="请输入备注"
                    type="textarea"
                    style="width: 500px"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </template>
          <!-- 基本信息 end -->
          <!-- 任务项设置   start -->
          <template>
            <div class="edit-title">任务项设置</div>
            <div class="edit-content" style="margin-bottom: 20px">
              <div class="associated-info">
                <div class="right-button">
                  <el-button type="primary" @click="addScene">
                    添加任务项
                  </el-button>
                  <el-button type="primary" @click="handleDelete">
                    删除任务项
                  </el-button>
                </div>
              </div>
              <el-table
                ref="multipleTable"
                :data="tableData"
                highlight-current-row
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="50"></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="sceneName"
                  label="场景名称"
                  width="160"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show">{{ row.sceneName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="sceneTypeName"
                  label="场景类型"
                  width="160"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show">{{ row.sceneTypeName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="thirdClassName"
                  label="商品名称"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="firstClassName"
                  label="商品分类"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="secondClassName"
                  label="商品开票名称"
                  width="160"
                  align="center"
                  header-align="center"
                >
                  <!-- <template slot-scope="{ row }">
                    <span v-if="row.show2">{{ row.secondClassName }}</span>
                  </template> -->
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="virtualTypeName"
                  label="商品类型"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="equipCode"
                  label="设备编号"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="realNo"
                  label="SIM卡号"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="commids"
                  label="中心识别码"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
              </el-table>
            </div>
            <div class="edit-content" style="margin-bottom: 20px">
              <el-form
                class="taskForm"
                :model="taskForm"
                :rules="taskFormRules"
                ref="taskForm"
              >
                <el-form-item label="问题时限" prop="replyLimit">
                  <el-popover
                    placement="right"
                    width="600"
                    trigger="click"
                    @show="handleShow"
                    @hide="handleHide"
                    ref="popover"
                  >
                    <el-table
                      :data="selectTable"
                      @row-click="rowClick"
                      height="400"
                    >
                      <el-table-column
                        width="160"
                        property="replyTime"
                        label="回复时限"
                        header-align="center"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        width="100"
                        property="questionType"
                        label="问题类型"
                        header-align="center"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        width="160"
                        property="specificItem"
                        label="具体项目"
                        header-align="center"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        width="160"
                        property="questionContext"
                        label="问题内容"
                        header-align="center"
                        align="center"
                      ></el-table-column>
                    </el-table>
                    <el-input
                      placeholder="选择问题时限"
                      slot="reference"
                      style="width:300px;"
                      ref="selectInput"
                      @input="handleLoadLimtFilter"
                      v-model="taskForm.replyLimit"
                    >
                      <i
                        slot="suffix"
                        class="e6-icon-arrow-b_line"
                        v-if="showSel"
                      ></i>
                      <i slot="suffix" class="e6-icon-arrow-t_line" v-else></i>
                    </el-input>
                  </el-popover>
                </el-form-item>
                <el-form-item label="服务内容" prop="serviceContent">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="taskForm.serviceContent"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </template>
          <!-- 任务项设置   end -->
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </div>
      </section>
    </div>
    <!-- 选择场景 -->
    <select-scene
      :selectSceneDialog="selectSceneDialog"
      @handleClose="handleClose"
      @getData="getData"
      :corpId="baseInfoForm.corpId"
    ></select-scene>
    <!-- 添加联系人弹框 -->
    <add-concat-dialog
      :addContactVisible="addContactVisible"
      @handleClose="handleClose"
      :corpName="baseInfoForm.corpName"
      :corpId="baseInfoForm.corpId"
      @updateList="updateList"
      :isNeedReq="true"
    ></add-concat-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { addReplyService, getLimitList } from "@/api";
import AddConcatDialog from "@/components/addConcatDialog.vue";
import { printError } from "@/utils/util";
import addTask from "@/mixins/addTask";
import addService from "@/mixins/addService";
import SelectScene from "@/components/workOrderManage/selectScene.vue";
import allUserSearch from "@/components/allUserSearch";
export default {
  name: "replyService",
  components: { AddConcatDialog, SelectScene, allUserSearch },
  data() {
    return {
      /*******任务项form***********/
      taskForm: {
        replyLimit: "", //回复时限
        project: "", //具体项目
        questionContent: "", //问题内容
        serviceContent: "" //服务内容
      },
      taskFormRules: {
        replyLimit: [
          {
            required: true,
            message: "请选择回复时限",
            trigger: ["blur", "change"]
          }
        ],
        serviceContent: [
          {
            required: true,
            message: "请填写服务内容",
            trigger: ["blur", "change"]
          }
        ]
      },
      /******问题时限下拉框********/
      searchValue: "",
      showSel: false, //是否显示下拉框
      selectTable: [] //回复时限下拉框数据
    };
  },
  computed: {
    rules() {
      let baseInfoFormRules = {};
      if (this.baseInfoForm.solueIsDept == 1) {
        baseInfoFormRules = {
          corpId: [
            {
              required: true,
              message: "请选择客户名称",
              trigger: ["blur", "change"]
            }
          ],

          expecttime: [
            {
              required: true,
              message: "请选择解决时间",
              trigger: ["blur", "change"]
            }
          ],
          solueIsDept: [
            {
              required: true,
              message: "请选择指派类型",
              trigger: ["blur", "change"]
            }
          ],
          departmentId: [
            {
              required: true,
              message: "请选择指派部门",
              trigger: ["blur", "change"]
            }
          ]
        };
      } else {
        baseInfoFormRules = {
          corpId: [
            {
              required: true,
              message: "请选择客户名称",
              trigger: ["blur", "change"]
            }
          ],
          expecttime: [
            {
              required: true,
              message: "请选择解决时间",
              trigger: ["blur", "change"]
            }
          ],
          defaultCity: [
            {
              required: true,
              message: "请选择地址",
              trigger: ["blur", "change"]
            }
          ],
          insAddress: [
            {
              required: true,
              message: "请输入详细地址",
              trigger: ["blur", "change"]
            }
          ],
          solueIsDept: [
            {
              required: true,
              message: "请选择指派类型",
              trigger: ["blur", "change"]
            }
          ],
          solveRpid: [
            {
              required: true,
              message: "请选择指派人",
              trigger: ["blur", "change"]
            }
          ]
        };
      }
      return baseInfoFormRules;
    }
  },
  created() {
    this.getLimitListReq("");
  },
  mixins: [base, addTask, addService],
  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.baseInfoForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //获取问题时限
    handleLoadLimtFilter: _.debounce(async function(val) {
      this.getLimitListReq(val);
    }, 300),
    //查询回复时限下拉框数据
    async getLimitListReq(val = "") {
      try {
        let res = await getLimitList({ searchValue: val });
        //基本信息
        this.selectTable = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击确定
    submit() {
      this.$refs.baseInfoForm.validate(valid1 => {
        this.$refs.taskForm.validate(valid2 => {
          if (valid1 && valid2) {
            if (!this.tableData.length) {
              this.$message.warning("请至少选择一个任务项");
              return;
            }
            this.baseInfoForm.contactName = this.filterSelect(
              this.contactList,
              "contactId",
              this.baseInfoForm.linkmanrpid
            ).contactName;
            this.baseInfoForm.corpName = this.filterSelect(
              this.corpList,
              "corpId",
              this.baseInfoForm.corpId
            ).corpName;
            this.addReplyServiceReq();
          }
        });
      });
    },
    //创建咨询回复服务单请求
    async addReplyServiceReq() {
      try {
        this.loading = true;
        let res = await addReplyService({
          ...this.baseInfoForm,
          solveRpid:
            this.baseInfoForm.solueIsDept == 1
              ? this.baseInfoForm.departmentId.join("")
              : this.baseInfoForm.solveRpid,
          ...this.taskForm,
          items: this.tableData
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.closeTag(this.$route);
          this.routerPush({
            name: "workOrderManage/serviceListManage",
            params: {
              refresh: true
            }
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },

    /********添加任务项***********/
    //点击下拉输入框
    clickInput() {
      this.$refs.selectInput.blur();
    },
    //下拉框显示
    handleShow() {
      this.showSel = true;
    },
    //下拉框隐藏
    handleHide() {
      this.showSel = false;
    },
    //点击下拉框table
    rowClick(row) {
      this.taskForm.replyLimit = row.replyTime;
      this.taskForm.project = row.specificItem;
      this.taskForm.questionContent = row.questionContext;
      this.taskForm.questionType = row.questionType;
      this.$refs.popover.doClose();
    },
    // 关闭弹框
    handleClose(visibleName) {
      this[visibleName] = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  .e6-vr-select {
    width: 100%;
  }
  .el-table {
    & tr th > .cell {
      height: 38px;
      line-height: 38px;
      color: #898c91;
      background-color: #ffffff;
    }
    .el-table__body .el-table__row:nth-child(2n) td {
      background-color: #ffffff;
    }
  }
}

.form-content {
  .el-input {
    width: 280px;
  }
}

.edit-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  .operate-action {
    height: 38px;
  }
}

.associated-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.table-input {
  width: 300px;
}
/deep/.dialog-box .el-dialog__body {
  padding: 20px;
  box-sizing: border-box;
}
/deep/.edit-content {
  .table-select {
    width: 200px;
  }
}
.taskForm {
  .e6-vr-select {
    width: 220px;
  }
  .el-textarea {
    width: 500px;
  }
}
</style>
